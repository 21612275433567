<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VToolbar flat>
            <VBtn
              icon
              @click="$router.go(-1)"
            >
              <VIcon>fal fa-chevron-left</VIcon>
            </VBtn>

            <VToolbarTitle>Топик {{ topicName }}</VToolbarTitle>

            <VSpacer />

            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  depressed
                  small
                  color="red"
                  class="mr-4"
                  v-bind="attrs"
                  v-on="on"
                  @click="removeTopic(testId, topicId)"
                >
                  <VIcon small>
                    fal fa-trash
                  </VIcon>
                </VBtn>
              </template>

              <span>Удаление топика</span>
            </VTooltip>

            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  depressed
                  color="warning"
                  small
                  class="mr-4"
                  :to="{
                    name : Names.R_TALENT_TEST_TOPIC_EDIT,
                    params : {
                      testId,
                      topicId
                    }
                  }"
                  v-bind="attrs"
                  v-on="on"
                >
                  <VIcon small>
                    fal fa-edit
                  </VIcon>
                </VBtn>
              </template>

              <span>Редактирование топика</span>
            </VTooltip>

            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  depressed
                  color="blue"
                  small
                  class="mr-4"
                  :to="{
                    name : Names.R_TALENT_TEST_TOPIC_QUESTIONS,
                    params : {
                      testId,
                      topicId
                    }
                  }"
                  v-bind="attrs"
                  v-on="on"
                >
                  <VIcon small>
                    fal fa-list
                  </VIcon>
                </VBtn>
              </template>

              <span>Список вопросов</span>
            </VTooltip>
          </VToolbar>

          <VCardText>
            <VExpansionPanels
              accordion
              flat
            >
              <VExpansionPanel>
                <VExpansionPanelHeader>JSON</VExpansionPanelHeader>
                <VExpansionPanelContent>
                  <code
                    class="elevation-0 d-block"
                    style="white-space: pre"
                    v-text="topicRaw"
                  />
                </VExpansionPanelContent>
              </VExpansionPanel>
            </VExpansionPanels>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'TopicView',
  inject: ['Names'],
  data() {
    return {
      topicName: '',
      topicRaw: null,
    };
  },
  computed: {
    testId() {
      return this.$route.params.testId;
    },
    topicId() {
      return this.$route.params.topicId;
    },
  },
  async created() {
    try {
      const topicResponse = await this.getTopics(this.testId);
      const currentTopic = topicResponse.find((topic) => topic.id === parseInt(this.topicId, 10));

      if (currentTopic) {
        this.topicName = currentTopic.name;
        this.topicRaw = currentTopic;
      } else {
        this.$di.notify.snackError('Топик не найден');
      }
    } catch (error) {
      this.$di.notify.errorHandler(error);
    }
  },
  methods: {
    getTopics(testId) {
      return this.$di.api.Talent.topicsGet({ testId });
    },

    async removeTopic(testId, topicId) {
      try {
        await this.$di.api.Talent.topicDelete({ testId, topicId });
        this.$di.notify.snackSuccess('Топик успешно удалён');

        this.$router.push({ name: this.Names.R_TALENT_TEST_TOPICS, params: { testId: this.testId } });
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
  },
};
</script>
