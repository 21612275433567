var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TTView',[_c('VRow',[_c('VCol',[_c('VCard',[_c('VToolbar',{attrs:{"flat":""}},[_c('VBtn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('VIcon',[_vm._v("fal fa-chevron-left")])],1),_c('VToolbarTitle',[_vm._v("Топик "+_vm._s(_vm.topicName))]),_c('VSpacer'),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"icon":"","depressed":"","small":"","color":"red"},on:{"click":function($event){return _vm.removeTopic(_vm.testId, _vm.topicId)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-trash ")])],1)]}}])},[_c('span',[_vm._v("Удаление топика")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"icon":"","depressed":"","color":"warning","small":"","to":{
                  name : _vm.Names.R_TALENT_TEST_TOPIC_EDIT,
                  params : {
                    testId: _vm.testId,
                    topicId: _vm.topicId
                  }
                }}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-edit ")])],1)]}}])},[_c('span',[_vm._v("Редактирование топика")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"icon":"","depressed":"","color":"blue","small":"","to":{
                  name : _vm.Names.R_TALENT_TEST_TOPIC_QUESTIONS,
                  params : {
                    testId: _vm.testId,
                    topicId: _vm.topicId
                  }
                }}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-list ")])],1)]}}])},[_c('span',[_vm._v("Список вопросов")])])],1),_c('VCardText',[_c('VExpansionPanels',{attrs:{"accordion":"","flat":""}},[_c('VExpansionPanel',[_c('VExpansionPanelHeader',[_vm._v("JSON")]),_c('VExpansionPanelContent',[_c('code',{staticClass:"elevation-0 d-block",staticStyle:{"white-space":"pre"},domProps:{"textContent":_vm._s(_vm.topicRaw)}})])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }